<template>
  <div class="container">
    <NavBar></NavBar>
    <router-view/>
    <FooterVue></FooterVue>
  </div>
</template>
  
<style>

.title {
    text-align: center;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}


</style>
